<template>
  <div class="Home" id="containerHome">
    <resultsPage></resultsPage>
  </div>
</template>

<script>
import resultsPage from '@/pages/resultsPage/resultsPage'
import validateMaintenance from '@/utils/validateMaintenance'
import { useStore } from 'vuex'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'Home',
  components: {
    resultsPage
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isSearching = ref(false)
    const isTyping = ref(false)
    const randomStartPoint = ref(0)

    onMounted(() => {
      store.commit({
        type: 'SET_LOADING_APP',
        loading: true
      })

      randomStartPoint.value = route.query.r ? route.query.k : Math.floor(Math.random() * (2000 - 100 + 1)) + 100

      if (store.state.pagination.page === 1) {
        store.commit({
          type: 'SET_INITIAL_OFFSET',
          initialOffset: randomStartPoint.value
        })
      }

      store.dispatch({
        type: 'getRandomPeople',
        count: 48,
        k: randomStartPoint.value
      }).then(response => {
        store.state.totalResultsCount = response.data['@odata.count']
        store.state.results = response.data.value
        store.state.firstTime = true
        store.state.randomOffset = randomStartPoint.value

        store.commit({
          type: 'SET_PAGINATION',
          size: Number(48),
          page: route.query.r ? Number(route.query.p) : 1
        })
        store.commit({
          type: 'SET_LOADING_APP',
          loading: false
        })

        router.push({
          query: {
            p: route.query.r ? Number(route.query.p) : 1,
            k: store.state.randomOffset,
            gv: store.state?.isGridView
          }
        })

        const navigation = store.state.navigation
        const selector = route.query.q || route.query.f ? '.containerResultsPage' : '#containerHome'
        if (navigation.fromDetailView) {
          document.querySelector(selector).style.height = `${navigation.appHeigth}px`
          document.getElementById('app').scrollTop = navigation.scrollPosition
          setTimeout(() => {
            document.querySelector(selector).style.height = 'auto'
          }, 1200)
        }
      }).catch((error) => validateMaintenance(error))
      store.dispatch('clearStore')
    })

    return {
      store,
      route,
      router,
      isSearching,
      isTyping
    }
  }
}
</script>
